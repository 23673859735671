@import '../assets/theme.scss';

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  background-color: #000000;
}

html {
  font-size: 10px;
}

#root {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /* enable smooth scrolling on iOS */
  -webkit-overflow-scrolling: touch;
}
